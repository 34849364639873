<template>
  <div bodyClasses="p-0">
    <template>
      <div class="d-flex justify-content-between align-items-center mt-4 pl-3">
        <h3 class="mb-0">Jobs list</h3>
        <div class="d-flex align-items-center">
          <el-input placeholder="Search..." v-model="searchQuery" @input="handleSearch"
           class="search-form mr-3" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search text-dark"></i>
          </el-input>
        </div>
      </div>
    </template>
    <div class="p-3">
      <el-table
        ref="jobTableRef"
        class="table-responsive custom-table table-flush"
        header-row-class-name="thead-light"
        :data="paginatedData"
        show-empty
        @sort-change="handleSortChange"
      >
        <template #empty>
          <span class="table-empty">
            There are currently no open jobs that contain this pipeline stage.
          </span>
        </template>
        <el-table-column prop="jobTitle" label="Job Title" :width="columnWidths.jobTitle" sortable>
        <template v-slot="{ row }">
          <div class="d-flex align-items-center" @click="handleJobAction(row, $event)">
            <base-switch class="custom-switch mr-2" v-model="row.isSelected"
            onText="On" offText="Off"></base-switch>
            <span>{{ row.jobTitle }}</span>
          </div>
        </template>
        </el-table-column>
        <el-table-column prop="createdDate" label="Created date" sortable>
          <template v-slot="{ row }">
            {{ row.createdDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="location" label="Location" sortable>
        </el-table-column>
        <el-table-column prop="department" label="Department" sortable>
        </el-table-column>
        <el-table-column prop="recruiter" label="Recruiter" :width="columnWidths.recruiter" sortable>
        </el-table-column>
      </el-table>

      <!-- Pagination component -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="sortedAndFilteredData.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handlePageChange"
        class="m-4 float-right custom-pagination"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, Pagination, Input} from 'element-ui';
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Input.name]: Input,
  },
  data() {
    return {
      search: '',
      searchQuery: '',
      loading: false,
      currentPage: 1,
      pageSize: 10,
      sortKey: '',
      sortOrder: '',
      tableData: [
        {id: 1, jobTitle: 'UX/UI Designer', createdDate: '2016-05-03', location: 'No. 189, Grove St, Los Angeles', department: 'Engineering', recruiter: 'Tom', isSelected: false},
        {id: 2, jobTitle: 'Software Engineer', createdDate: '2016-06-15', location: 'No. 123, Main St, San Francisco', department: 'Engineering', recruiter: 'Jerry', isSelected: false},
        {id: 3, jobTitle: 'Project Manager', createdDate: '2016-07-20', location: 'No. 456, Maple Ave, Seattle', department: 'Management', recruiter: 'Linda', isSelected: false},
        {id: 4, jobTitle: 'QA Tester', createdDate: '2016-08-11', location: 'No. 789, Oak St, Denver', department: 'Quality Assurance', recruiter: 'Sarah', isSelected: false},
        {id: 5, jobTitle: 'DevOps Engineer', createdDate: '2016-09-05', location: 'No. 101, Pine St, Boston', department: 'Operations', recruiter: 'Alex', isSelected: false},
        {id: 6, jobTitle: 'Product Owner', createdDate: '2016-10-14', location: 'No. 202, Birch Rd, Austin', department: 'Product Management', recruiter: 'Chris', isSelected: false},
        {id: 7, jobTitle: 'Scrum Master', createdDate: '2016-11-19', location: 'No. 303, Cedar Blvd, Miami', department: 'Management', recruiter: 'Pat', isSelected: false},
        {id: 8, jobTitle: 'Backend Developer', createdDate: '2016-12-23', location: 'No. 404, Elm St, New York', department: 'Engineering', recruiter: 'Taylor', isSelected: false},
        {id: 9, jobTitle: 'Frontend Developer', createdDate: '2017-01-07', location: 'No. 505, Willow Way, Chicago', department: 'Engineering', recruiter: 'Jordan', isSelected: false},
        {id: 10, jobTitle: 'Data Scientist', createdDate: '2017-02-13', location: 'No. 606, Ash Ln, Portland', department: 'Data Science', recruiter: 'Morgan', isSelected: false},
        {id: 11, jobTitle: 'Machine Learning Engineer', createdDate: '2017-03-18', location: 'No. 707, Fir Dr, Houston', department: 'Data Science', recruiter: 'Drew', isSelected: false},
        {id: 12, jobTitle: 'System Administrator', createdDate: '2017-04-22', location: 'No. 808, Spruce Ct, Phoenix', department: 'IT', recruiter: 'Sam', isSelected: false},
        {id: 13, jobTitle: 'Network Engineer', createdDate: '2017-05-27', location: 'No. 909, Cypress Ave, Philadelphia', department: 'IT', recruiter: 'Casey', isSelected: false},
        {id: 14, jobTitle: 'Security Analyst', createdDate: '2017-06-30', location: 'No. 1010, Redwood Dr, Dallas', department: 'Security', recruiter: 'Blake', isSelected: false},
        {id: 15, jobTitle: 'Business Analyst', createdDate: '2017-07-15', location: 'No. 1111, Palm St, San Diego', department: 'Business Analysis', recruiter: 'Riley', isSelected: false},
        {id: 16, jobTitle: 'HR Manager', createdDate: '2017-08-20', location: 'No. 1212, Cypress Ave, San Jose', department: 'Human Resources', recruiter: 'Quinn', isSelected: false},
        {id: 17, jobTitle: 'Recruiter', createdDate: '2017-09-25', location: 'No. 1313, Spruce Ct, San Francisco', department: 'Human Resources', recruiter: 'Harper', isSelected: false},
        {id: 18, jobTitle: 'Marketing Specialist', createdDate: '2017-10-30', location: 'No. 1414, Pine St, Boston', department: 'Marketing', recruiter: 'Avery', isSelected: false},
        {id: 19, jobTitle: 'Content Writer', createdDate: '2017-11-15', location: 'No. 1515, Oak St, Denver', department: 'Marketing', recruiter: 'Parker', isSelected: false},
        {id: 20, jobTitle: 'Graphic Designer', createdDate: '2017-12-20', location: 'No. 1616, Birch Rd, Austin', department: 'Design', recruiter: 'Robin', isSelected: false},
        {id: 21, jobTitle: 'Sales Manager', createdDate: '2018-01-25', location: 'No. 1717, Maple Ave, Seattle', department: 'Sales', recruiter: 'Kendall', isSelected: false},
        {id: 22, jobTitle: 'Customer Support', createdDate: '2018-02-28', location: 'No. 1818, Main St, San Francisco', department: 'Support', recruiter: 'Logan', isSelected: false},
        {id: 23, jobTitle: 'Accountant', createdDate: '2018-03-15', location: 'No. 1919, Grove St, Los Angeles', department: 'Finance', recruiter: 'Sydney', isSelected: false},
        {id: 24, jobTitle: 'Financial Analyst', createdDate: '2018-04-18', location: 'No. 2020, Willow Way, Chicago', department: 'Finance', recruiter: 'Cameron', isSelected: false},
      ],
      columnWidths: {
        jobTitle: '300px',
        recruiter: '150px',
      },
      isAddClassToTable: false,
    };
  },
  props: {
    isEnabled: Boolean,
  },
  watch: {
    isEnabled: {
      handler: function(value) {
        this.isAddClassToTable = value;
        this.addTbodyClass();
      },
    },
  },
  computed: {
    // filteredData() {
    //   if (!this.searchQuery) {
    //     return this.tableData;
    //   }
    //   return this.tableData.filter((item) => {
    //     return Object.keys(item).some((key) => {
    //       return String(item[key]).toLowerCase().includes(this.searchQuery.toLowerCase());
    //     });
    //   });
    // },
    sortedAndFilteredData() {
      let data = this.tableData;
      // Filtering
      if (this.searchQuery) {
        const searchLower = this.searchQuery.trim().toLowerCase();
        data = data.filter((item) => {
          return Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchLower),
          );
        });
      }
      // Sorting
      if (this.sortKey) {
        data = data.slice().sort((a, b) => {
          let result = 0;
          if (a[this.sortKey] < b[this.sortKey]) {
            result = -1;
          } else if (a[this.sortKey] > b[this.sortKey]) {
            result = 1;
          }
          return this.sortOrder === 'ascending' ? result : -result;
        });
      }
      return data;
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.sortedAndFilteredData.slice(start, end);
    },
  },
  // created() {
  //   this.initializeTableData();
  // },
  methods: {
    handleSearch() {
      this.currentPage = 1;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    handleSortChange({prop, order}) {
      this.sortKey = prop;
      this.sortOrder = order;
      this.currentPage = 1; // Reset to first page on search
    },
    initializeTableData() {
      this.tableData.forEach((item) => {
        item.switchState = this.isEnabled;
      });
    },
    addTbodyClass() {
      if (this.$refs.jobTableRef) {
        const tableElement = this.$refs.jobTableRef.$el;
        const tbody = tableElement.querySelector('tbody');
        if (!this.isAddClassToTable) {
          tbody.classList.add('grayed-out');
        } else {
          tbody.classList.remove('grayed-out');
        }
      }
    },
    handleJobAction(row, event) {
      // Find the item in the original tableData and update it
      if (event.target.tagName == 'INPUT') {
        const item = this.tableData.find((item) => item.id === row.id);
        if (item) {
          item.isSelected = !item.isSelected;
        }
      }
    },
  },
  mounted() {
    this.addTbodyClass();
  },
};
</script>
<style>
.custom-switch{
  width: 1rem !important;
}
.custom-table {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  overflow: hidden;
  border-collapse: separate;
}
.table-empty{
  font-size: 13px;
  font-weight: 400;
  line-height: 17.7px;
  text-align: left;
}
.grayed-out {
  background-color: #e9ecef;
}
</style>
