<template>
  <div class="card mt-3 p-3 templates-list">
    <template v-if="onboardings.length > 0">
      <div v-for="onboard in onboardings" :key="onboard.id"
      class="app-border p-3 position-relative mb-3 active-element"
      :class="[{'active': !!value && (onboard.id === value.id) },{'greyed-out': !isOnboarding }]"
      @click="changeTemplate(onboard)">
        <div class="mb-2 name"><b>{{onboard.name}}</b></div>
        <div class="font-400 gray-600-color pre-md-text">{{onboard.message}}</div>
        <div class="close-btn"
        :class="[{'greyed-out': !isOnboarding }]"
         @click="tryRemoveTemplate(onboard.id)"><img :src="closeImg" /></div>
      </div>
    </template>
    <template v-else>
      <div class="no-templates p-3">
        Fill out the fields and click Save to create your first template
      </div>
    </template>
    <confirmation-modal
      ref="deletionConfirmationModal"
      header="Delete template?"
      question="Are you sure you want to delete this onboarding template?"
      cancelBtnText="Cancel"
      confirmBtnText="Delete"
      @on-accept="removeTemplate"
    />
  </div>
</template>
<script>
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {mapActions} from 'vuex';
import {DELETE_ONBOARD_TEMPLATE_REQUEST} from '@/store/storeActions';
export default {
  components: {
    ConfirmationModal,
  },
  data: function() {
    return {
      closeImg: 'img/tools/close.svg',
    };
  },
  props: {
    isOnboarding: Boolean,
    onboardings: Array,
    value: Object,
    formOnboardBlocked: Boolean,
    unsavedOnboardFormData: Object,
  },
  methods: {
    ...mapActions([
      DELETE_ONBOARD_TEMPLATE_REQUEST,
    ]),
    removeTemplate: function(id) {
      if (id) {
        this.DELETE_ONBOARD_TEMPLATE_REQUEST({id});
      } else {
        this.$emit('delete-onboard-template');
      }
    },
    changeTemplate(onboard) {
      if (this.isOnboarding) {
        if (this.formOnboardBlocked && onboard.id !== this.unsavedOnboardFormData.id) {
          this.$emit('save-onboard-confirmation', {data: {...this.unsavedOnboardFormData}, onboard});
        } else {
          this.$emit('input', onboard);
        }
      }
    },
    tryRemoveTemplate: function(id) {
      if (this.$refs.deletionConfirmationModal && this.isOnboarding) {
        this.$refs.deletionConfirmationModal.openModal(id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';
.templates-list{
  max-height: 80vh;
  overflow-y: auto;
}
.close-btn{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.no-templates{
  border: 3px dashed $gray-300;
  background: $gray-100;
  color: $gray-500;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1.2px;
}
.active{
  border-color: $primary;
  .name{
    color: $primary;
  }
}
</style>
