<template>
  <div class="card p-3 templates-list">
    <template v-if="(interviewReminders.length > 0)">
      <div v-for="template in interviewReminders" :key="template.id"
      class="app-border p-3 position-relative mb-3 active-element"
      :class="[{'active': !!value && (template.id === value.id) },{'greyed-out': !on }]"
      @click="changeTemplate(template)">
      <div class="mb-2 name"><b>{{template.name}}</b></div>
      <div class="font-400 gray-600-color pre-md-text">{{template.message.replace(/\\/g, "")}}</div>
      </div>
    </template>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
  components: {},
  props: {
    interviewReminders: Array,
    value: Object,
    formBlocked: Boolean,
    unsavedFormData: Object,
    on: Boolean,
  },
  methods: {
    ...mapActions([]),
    changeTemplate(template) {
      if (this.on) {
        if (this.formBlocked && template.id !== this.unsavedFormData.id) {
          this.$emit('save-template-confirmation', {data: {...this.unsavedFormData}, template});
        } else {
          this.$emit('input', template);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';
.templates-list{
  max-height: 80vh;
  overflow-y: auto;
}
.close-btn{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.no-templates{
  border: 3px dashed $gray-300;
  background: $gray-100;
  color: $gray-500;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1.2px;
}
.active{
  border-color: $primary;
  .name{
    color: $primary;
  }
}
</style>
