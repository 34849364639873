<template>
  <div>
    <div class="d-flex align-items-center justify-content-between p-3 pb-2 stage-title-main">
      <div class="stage-title">{{ name }}</div>
    </div>
    <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
      <b-form @submit.prevent="validate().then(save)">
        <b-row no-gutters>
          <b-col md="3" v-for="(interviewOption, index) in interviewOptions" :key="`button_${index}`"
           class="interviewOptionCol">
            <b-button
              @click="toggleOption(interviewOption, index, $event)"
              class="w-100 bg-white d-flex justify-content-between align-items-center interviewOption"
              :class="{ 'selected': selectedOptionIndex === index,
              'no-border-right': index === interviewOptions.length - 1}"
            >
              <span>{{ interviewOption.optionText }}</span>
              <base-switch v-model="interviewOption.isSelected" onText="On" offText="Off"></base-switch>
            </b-button>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col md="12" class="p-3">
            <label class="form-control-label">Message</label>
            <app-textarea v-model="selectedOption.message" @input="changeInterviewFormHandler()"
             placeholder="Type here..."
            rules="required" ref="messageBlock" class="template-input" />
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex mt-2">
                <b-button pill v-for="(shortcode, index) in shortcodes" :key="`shortcode_${index}`"
                  variant="outline-primary" class="d-flex justify-content-between align-items-center automation-btn"
                  size="sm" @click="addShortcode(shortcode)">
                  <span>{{ shortcode }}</span>
                  <b-icon icon="plus" class="ml-1 automation-plus-icon"></b-icon>
                </b-button>
                <b-dropdown class="more-variable-dropdown" no-caret variant="outline-primary" size="sm" right>
                  <template #button-content>
                    {{ 'More variables' }} <b-icon icon="chevron-down" class="ml-1"></b-icon>
                  </template>
                  <b-dropdown-item v-for="(item, index) in moreFilterVariables" :key="index"
                    class="ml-2 more-variable-dropdown" text="More variables" variant="outline-primary" size="sm" right
                    @click="addShortcode(item)" :class="{ 'no-border': index === moreFilterVariables.length - 1 }">
                    {{ item }} <b-icon icon="plus" class="ml-1 automation-plus-icon"></b-icon>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <p class="text-right ml-2 mt-2" :class="{ 'text-danger': message.length > messageLimit }">
                {{ messageInstruction }}
              </p>
            </div>
            <div class="d-flex align-items-center">
              <span>{{selectedOption.firstText}}</span>
              <base-input v-model="hours" type="text" @input="acceptHours" name="hours"
                class="hour-input mt-4 ml-1 mr-1"></base-input>
              <span> {{selectedOption.timeText }} {{selectedOption.secondText}}</span>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-outline-primary" :disabled="!isSaveEnabled" @click.prevent="cancelingEditing">
                Cancel
              </button>
              <button class="btn btn-primary" :disabled="invalid || !isSaveEnabled">
                Save
              </button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import AppTextarea from '@/components/App/controls/AppTextarea.vue';

export default {
  components: {
    AppTextarea,
  },
  props: {
    selectedItem: Object,
    isSaveEnabled: Boolean,
  },
  data() {
    const shortcodes = [
      'First Name',
      'Last Name',
      'Recruiter Name',
      'Job Title',
      'Interview Time',
      'Interview Date',
    ];
    const moreFilterVariables = [
      'Job Location',
      'Job Department',
    ];
    const interviewOptions = [{
      optionText: 'Confirmation',
      firstText: 'Message will be sent',
      defaultTime: 5,
      timeText: 'minutes',
      secondText: ' after the interview is scheduled in your ATS',
      isSelected: false,
      message: '',
    },
    {
      optionText: 'Day Before',
      firstText: 'Message will be sent',
      defaultTime: 24,
      timeText: 'hours',
      secondText: ' before the scheduled start time',
      isSelected: false,
      message: '',
    },
    {
      optionText: 'Day Of',
      firstText: 'Message will be sent',
      defaultTime: 2,
      timeText: 'hours',
      secondText: ' before the scheduled start time',
      isSelected: false,
      message: '',
    },
    {
      optionText: 'Follow Up',
      firstText: 'Message will be sent',
      defaultTime: 2,
      timeText: 'hours',
      secondText: ' after the scheduled start time',
      isSelected: false,
      message: '',
    }];
    return {
      shortcodes,
      name: '',
      message: interviewOptions[0].message || '',
      messageLimit: 400,
      hours: interviewOptions[0].defaultTime || null,
      moreFilterVariables,
      interviewOptions,
      selectedOption: interviewOptions[0],
      selectedOptionIndex: 0,
      isEnabled: this.selectedItem.isEnabled,
    };
  },
  watch: {
    selectedOption: {
      handler: function(value) {
        this.hours = value.defaultTime;
      },
      immediate: true,
    },
    selectedItem: {
      handler: function(value) {
        this.initializeFields(value);
      },
      immediate: true,
    },
    hours: {
      handler: function(value) {
        this.hours = value;
        this.changeInterviewFormHandler('hours', value);
        if (this.selectedOption.optionText == 'Confirmation') {
          this.selectedOption.timeText = this.hours > 1 ? 'minutes' : 'minute';
        } if (this.selectedOption.optionText !== 'Confirmation') {
          this.selectedOption.timeText = this.hours > 1 ? 'hours' : 'hour';
        }
      },
      immediate: true,
    },
    message: {
      handler: function(value) {
        this.changeInterviewFormHandler('message', value);
      },
    },
  },
  computed: {
    messageInstruction: function() {
      return this.selectedOption.message.length + '/' + this.messageLimit;
    },
  },
  methods: {
    acceptHours: function() {
      const hours = this.hours.replace(/\D/g, '');
      if (this.selectedOption.optionText == 'Confirmation') {
        this.hours = /^(0?[1-9]|[1-5]\d|60)$/.test(hours) ? hours : '';
      }
      if (this.selectedOption.optionText !== 'Confirmation') {
        this.hours = /^(0?[1-9]|1\d|2[0-4])$/.test(hours) ? hours : '';
      }
    },
    addShortcode: function(shortcode) {
      let cursorPosition = 0;
      const {messageBlock} = this.$refs;
      if (!!messageBlock) {
        const {textarea} = messageBlock.$refs;
        textarea.focus();
        cursorPosition = textarea ? textarea.selectionStart : 0;
      }
      this.selectedOption.message = `${this.selectedOption.message.slice(0, cursorPosition)}[${shortcode}]${this.selectedOption.message.slice(cursorPosition)}`;
    },
    initializeFields: function(template) {
      if (template) {
        this.name = template.name;
        // this.message = template.message.replace(/\\/g, '');
        this.message = this.selectedOption.message;
        this.hours = this.selectedOption.defaultTime;
        this.isEnabled = template.isEnabled;
      }
    },
    toggleOption: function(item, index, event) {
      this.selectedOptionIndex = index;
      if (event.target.tagName == 'INPUT') {
        this.interviewOptions[index].isSelected = !this.interviewOptions[index].isSelected;
        this.selectedOption = this.interviewOptions[index];
        return true;
      }
      if (event.target.tagName !== 'INPUT') {
        // this.selectedOption = [];
        // this.interviewOptions.map((item)=>{
        //   item.isSelected = false;
        // });
        this.selectedOption = item;
        return true;
      }
    },
    changeInterviewFormHandler: function(field, value) {
      this.selectedItem ? (this.selectedItem && value !==
        this.selectedItem[field]) : (value !== '');
      const unsavedFormData = {
        id: this.selectedItem.id,
        message: this.selectedOption.message,
        hours: this.hours,
      };
      const checkOption = this.interviewOptions.some((option) => option.message.trim() !== '');
      if (checkOption) {
        this.$emit('form-interview-block-change', {blocked: checkOption, unsavedFormData});
      }
    },
    cancelingEditing: function() {
      this.initializeFields(this.selectedItem);
      this.interviewOptions.map((item)=>{
        item.isSelected = false;
      });
    },
    save: function() {
      this.$emit('save-form-click');
      // this.cancelingEditing();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/custom/_app-vars.scss";
.hour-input .form-control {
  width: 2.75rem;
  border-radius: 10px;
  text-align: center;
  height: 2.5rem;
  font-size: 0.80rem;
}

.hour-input .form-group {
  margin-bottom: 0px;
}

.more-variable-dropdown .dropdown-toggle {
  border-radius: 50px;
}

.more-variable-dropdown .dropdown-menu {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: left !important;
}

.more-variable-dropdown .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;
}

.no-border a {
  border-bottom: none !important;
}

.more-variable-dropdown .dropdown-item i {
  color: #525760;
}
.active {
  background-color: $primary-color;
  color: #fff;
}
.interviewOption {
  border: none;
  border-radius: 0;
  box-shadow: none !important;
}

.interviewOption:hover,
.interviewOption:focus,
.interviewOption:active {
  box-shadow: none !important;
  background-color: #fff !important;
  border-top: 0px;
  transform: translateY(0px) !important;
  -webkit-transform: translateY(0px) !important;
}

.interviewOptionCol:has(.selected) {
  border-bottom: 2px solid $primary-color !important;
}

.interviewOptionCol {
  border: 1px solid #E4E6EC;
  border-top: 0px;
}

.interviewOptionCol:last-child {
  border-right: none;
}
</style>
